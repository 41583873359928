require(['jquery'],
    ($) => {

        'use strict';

        const handleQtyUpdate = (e, isIncrease) => {
            const $input = $(e.target).siblings('.cart-item-qty');
            const value = parseInt($input.val());

            if (!isIncrease && value === 1) {
                return;
            }

            if (isIncrease) {
                $input.val(value + 1);
            }

            else {
                $input.val(value - 1);
            }

            $input.siblings('.update-cart-item').trigger('click');
        };

        const addEvents = () => {
            $('#header-cart').on('change', '.product-item .details-qty .cart-item-qty', (e) => {
                const $button = $(e.target).next('.update-cart-item');
                if (!$button.length || $button.is(':hidden')) {
                    return;
                }
                $button.click();
            });

            $('.minicart-wrapper').on('click', '.product-item .details-qty .qty-add', (e) => {
                handleQtyUpdate(e, true);
            });

            $('.minicart-wrapper').on('click', '.product-item .details-qty .qty-substract', (e) => {
                handleQtyUpdate(e, false);
            });
        };

        const init = () => {
            addEvents();
        };

        init();

    });
