require([
    'mapStyles', 'domReady!'
],
    (mapStyles) => {

        'use strict';

        const maps = (() => {

            const icons = [
                {
                    url: require.toUrl('images/maps/marker.png'),
                    h: 60,
                    w: 40
                }
            ];

            const maps = [
                {
                    id: 'headquarters-map',
                    center: {
                        lat: 52.4732643,
                        lng: 17.2855046
                    },
                    zoom: 12,

                    markers: [{
                        position: {
                            lat: 52.4732643,
                            lng: 17.2855046
                        },
                        icon: icons[0]
                    }]
                }
            ];

            const getSettings = mapEl => {
                return Object.assign({}, mapEl.dataset);
            };

            const getIcon = icon => {

                google = window.google;

                if (icon.hasOwnProperty('scaledSize')) {
                    return icon;
                }

                return typeof google !== 'undefined' ? {
                    url: icon.url,
                    scaledSize: new google.maps.Size(icon.w, icon.h),
                    origin: new google.maps.Point(0, 0)
                } : false;
            };

            const getMarker = (marker, map) => {
                google = window.google;

                if (typeof google !== 'undefined') {

                    const markerIcon = marker.icon || icons[0];

                    const markerOptions = {
                        map: map,
                        icon: getIcon(markerIcon),
                        position: marker.position,
                        draggable: false,
                        label: typeof marker.label !== 'undefined' ? marker.label : ''
                    };

                    return new google.maps.Marker(markerOptions);
                }

                return false;
            };

            const getMarkers = (markers, map) => {
                return Array.isArray(markers) ? markers.map(marker => {
                    marker = getMarker(
                        marker,
                        map
                    );
                    return marker;
                }) : [];
            };

            const createMapObj = map => {
                const el = document.getElementById(map.id);

                if (!el) {
                    return false;
                }

                const settings = getSettings(el);
                map.el = el;

                if (settings.icon) {
                    map.markers.map(marker => {
                        marker.icon = marker.icon || icons[0];
                        marker.icon.url = settings.icon;
                        return marker;
                    });
                }

                return map;
            };

            const getMaps = maps => {
                return maps.filter(createMapObj);
            };

            const initMap = () => {
                const activeMaps = getMaps(maps);

                activeMaps.map(item => {
                    const mapContainer = item.el;

                    if (!mapContainer) {
                        return;
                    }

                    const center = item.center;

                    const map = new google.maps.Map(mapContainer, {
                        zoom: item.zoom || 7,
                        center: center,
                        styles: mapStyles
                    });

                    getMarkers(item.markers, map);

                    google.maps.event.addDomListener(window, 'resize', () => {
                        google.maps.event.trigger(map, 'resize');
                        map.setCenter(center);
                    });
                });
            };

            const loadScript = () => {
                if (typeof google === 'undefined') {
                    window.initMap = initMap;
                    const API_KEY = 'AIzaSyC2fnyeMzPleS2A5WEFNZBme5Hb9QUDarA';
                    var googleMaps = `https://maps.googleapis.com/maps/api/js?callback=initMap&key=${API_KEY}`;
                    var script = document.createElement('script');
                    script.src = googleMaps;
                    document.head.appendChild(script);
                }
            };

            const initMaps = (maps) => {
                if (maps.length) {
                    loadScript();
                }
            };

            const init = () => {
                initMaps(getMaps(maps));
            };

            return {
                init
            };

        })();

        maps.init();

    });
