require(['jquery'],
  ($) => {

    'use strict';

    const $button = $('.product-collateral .toggle-tabs').find('[data-tab="reviews"]');
    const reviewListSelector = '#product-review-container';

    const getTargetElement = (url) => {
        const targetId = url && url.split('#')[1];
        const $target = $(`#${targetId}`);
        return $target.length ? $target : $(reviewListSelector);
    };

    const getOffset = ($target) => {
        if (!$target.length) {
            return 0;
        }
        const targetOffset = $target.offset() && $target.offset().top;
        return targetOffset - $('#header').height();
    };

    const addEvents = () => {
        $('.reviews-actions .action').on('click', (e) => {
            $button.click();
            const $target = getTargetElement(e.currentTarget.href);
            const scrollTop = getOffset($target);

            if (!scrollTop) {
                return;
            }

            $('html, body').animate({ scrollTop });
        });
    };

    const init = () => {
        addEvents();
    };

    init();

});
