require([
    'jquery', 'tooltip', 'stickyjs', 'dotdotdot', 'lazysizes'
],
    ($, tooltip, Sticky) => {
        'use strict';

        const truncate = (() => {

            const options = {
                truncate: 'word',
                watch: 'window',
            };

            const dotdotSelectors = [
                '[data-truncate]',
                '.article-short'
            ];

            const truncateText = (selector) => {
                if (typeof $(selector).dotdotdot === 'function') {
                    $(selector).dotdotdot(options);
                }
            };

            const init = () => {
                const toDot = dotdotSelectors.join(', ');
                $(window).on('load', () => {
                    truncateText(toDot);
                });
            };

            return {
                init
            };

        })();

        const arrowBtn = (() => {

            const arrowBtn = document.querySelector('.scroll-down'),
                scrollTarget = document.querySelector('.banner-catalog'),
                header = document.querySelector('.page-header');

            const getOffset = () => {
                try {
                    return scrollTarget.offsetTop - header.offsetHeight + 'px';
                } catch (e) {
                    return '650px';
                }
            };

            const scrollDown = () => {
                $('html, body').animate({
                    scrollTop: getOffset()
                });
            };

            const addEvents = () => {
                if (arrowBtn) {
                    arrowBtn.addEventListener('click', scrollDown);
                }
            };

            const init = () => {
                addEvents();
            };

            return {
                init
            };

        })();

        const productFilters = (() => {

            const $filters = $('.toolbar-sorter'),
                $filtersToggle = $filters.find('.button-toggle-filters'),
                activeClass = 'filters-open';

            const toggleFilters = () => {
                $filters.toggleClass(activeClass);
            };

            const addEvents = () => {
                $filtersToggle.on('click', toggleFilters);
            };

            const init = () => {
                addEvents();
            };

            return {
                init
            };

        })();

        const stickInSidebar = (() => {

            const stickyBlocks = [
                '.catalog-product-view .sidebar-scrollable',
                '.cms-page-view .block-advice',
                '.blog-page .block-advice',
            ];

            const selectors = {
                container: '.page-main',
                header: '.page-header',
                parent: '.sidebar',
                block: '.block'
            };

            const default_offset = 80;

            const getOffset = (selector) => {
                const $firstBlock = $(selector).closest(selectors.parent).find(selectors.block).first();
                const offsetAdjust = $firstBlock.css('paddingTop') || 0;
                return $(selectors.header).height() - parseInt(offsetAdjust) + 20 || default_offset;
            };

            const allowInViewport = (selector) => window.innerHeight > $(selector).closest(selectors.parent).height() + getOffset(selector);
            const canStickElement = (selector) => Sticky && !!document.querySelector(selector);

            const getOptions = (selector) => ({
                marginTop: getOffset(selector),
                stickyFor: 1023,
                stickyContainer: selectors.container,
                wrap: true
            });

            const initStickyItem = (selector) => {
                if (!allowInViewport(selector)) {
                    return null;
                }
                const options = getOptions(selector);
                return new Sticky(selector, options);
            };

            const addEvents = selector => {
                if (!canStickElement(selector)) {
                    return;
                }

                initStickyItem(selector);
            };

            const init = () => {
                $(document).ready(() => stickyBlocks.forEach((selector) => addEvents(selector)));
            };

            return {
                init
            };

        })();

        const hideButtons = (() => {

            const init = () => {
                const input = $('#newsletter');

                input.on({
                    focus: () => {
                        $('.block-order').hide();
                    },
                    blur: () => {
                        $('.block-order').show();
                    }
                });
            };

            return {
                init
            };

        })();

        const toggleDelivery = (() => {

            const $toggle = $('.toggle-delivery');

            const init = () => {
                if (!$toggle.length) {
                    return;
                }
                $toggle.on('click', () => {
                    $('.product-delivery').slideToggle();
                    $toggle.toggleClass('active');
                });
            };
            return { init };
        })();

        const fixedHeader = (() => {
            let header = $('header');
            let body = $('body');
            let sticky = header.height();
            $(window).on('scroll', () => {
                if ($(window).scrollTop() > sticky) {
                    header.addClass('fixed');
                    body.css('paddingTop', sticky);
                } else {
                    header.removeClass('fixed');
                    body.css('paddingTop', 0);
                }
            });
        });

        truncate.init();
        stickInSidebar.init();
        arrowBtn.init();
        productFilters.init();
        hideButtons.init();
        tooltip.init('[data-tooltip]');
        toggleDelivery.init();
        fixedHeader();
    });
