require([
        'jquery',
    ],
    ($) => {

        const events = (() => {
            const cookieConsentClick = () => {
                $('.cookie-consent-link').on('click', (e) => {
                    e.preventDefault();
                    if (typeof CookieConsent !== "undefined") {
                        CookieConsent.renew();
                    }
                });
            };
            const init = () => {
                $(document).ready(() => cookieConsentClick());
            };

            return {
                init
            };

        })();

        events.init();
    });
