require(['jquery'],
    ($) => {

        const cartQty = (() => {
            const recalculateCart = ($input) => {
                $input.closest('.form-cart').find('button[data-cart-item-update]').click();
            };

            const addItems = (e) => {
                e.stopImmediatePropagation();
                const $input = $(e.target).parent().find('[id$="qty"]');
                const value = parseInt($input.val());
                $input.val(value + 1);

                recalculateCart($input);
            };

            const substractItems = (e) => {
                e.stopImmediatePropagation();
                const $input = $(e.target).parent().find('[id$="qty"]');
                const min = parseInt($input.attr('min')) || 1;
                const value = parseInt($input.val());
                if (value > min) {
                    $input.val(value - 1);
                }

                recalculateCart($input);
            };

            const addEvents = () => {
                $('.form-cart, .product-add-form').on('click', '.qty-substract', substractItems);
                $('.form-cart, .product-add-form').on('click', '.qty-add', addItems);
            };

            const init = () => {
                $(document).ready(() => addEvents());
            };

            return {
                init
            };

        })();

        const mobilePricePanel = (() => {
            const addEvents = () => {
                $('.mobile-price-toggle').on('click', (e) => {
                    $(e.target).closest('.block-content').toggleClass('expanded');
                });
            };

            const init = () => {
                $(document).ready(() => addEvents());
            };

            return {
                init
            };

        })();

        cartQty.init();
        mobilePricePanel.init();
    });
