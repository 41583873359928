require([
    'jquery', 'owlCarousel'
  ],
  ($) => {

	"use strict";

	const tabs = (() => {
		const activeClass = 'active';
		const $tabsContainer = $('.tabs-container');
		const $tabsMenu = $tabsContainer.find('.tabs-menu__item');
		const $tabsContent = $tabsContainer.find('.tab-content');

		const toggleActiveElement = ($element) => {
			$element.addClass(activeClass);
			$element.siblings().removeClass(activeClass);
		};

		const setActiveTabContent = (tabIndex) => {
			const $activeTab = $tabsContent.eq(tabIndex);
			toggleActiveElement($activeTab);
		};

		const setActiveTab = (event) => {
			const $activeMenuItem = $(event.target);
			const tabIndex = $activeMenuItem.index() || 0;
			toggleActiveElement($activeMenuItem);
			setActiveTabContent(tabIndex);
		};

		const addEvents = () => {
			$tabsMenu.on('click', setActiveTab);
		};

		const init = () => {
			addEvents();
		};

		return {
			init,
		};

	})();

	const productTabs = (() => {
        const tabItemSelector = '.tab-item';
		const $tabsWrapper = $('.collateral-tabs');
		const $tabsContainers = $tabsWrapper.find('.tab-container');
		const $tabsHeaders = $tabsWrapper.find('.tab');
		const currentClass = 'current';
        const $tabsToggle = $('.toggle-tabs');
        const $tabItems = $tabsToggle.find(tabItemSelector);
		let carousel;
		let currentIndex;

		const mobileMax = 767;

		const setCurrent = () => {
            let current = $tabItems.filter((i, el) => el.classList.contains(currentClass)).index();
			current = current > -1 ? current : 0;
			currentIndex = current;
		};

		const toggleTab = (index) => {
            currentIndex = index;
			const $currentTab = $tabItems.eq(index);
            const $items = $tabItems.add($tabsContainers).add($tabsHeaders);
            const $currentItems = $currentTab.add($tabsContainers.eq(index)).add($tabsHeaders.eq(index));
            $items.removeClass(currentClass);
            $currentItems.addClass(currentClass);
		};

		const carouselOptions = {
			dots: false,
			nav: false,
            autoWidth: false,
            items: $tabItems.length > 1 ? 2 : 1,
            center: true,
            margin: 0,
            loop: false,
            touchDrag: $tabItems.length > 1,
			onInitialized: () => {
				$tabsToggle.on('click', tabItemSelector, (e) => {
					const index = $(e.currentTarget).index(tabItemSelector) || 0;
					carousel.trigger('to.owl.carousel', [index]);
				});
			},
			onTranslate: e => {
				toggleTab(e.item.index);
			}
        };

		const onResize = () => {
			setCurrent();

			if (window.innerWidth > mobileMax) {
				$tabsToggle.trigger('destroy.owl.carousel');
				toggleTab(currentIndex);
			} else {
				carousel = $tabsToggle.owlCarousel(carouselOptions);
				carousel.trigger('to.owl.carousel', [currentIndex]);
			}
        };

        const onClick = () => {
            $tabsToggle.on('click', tabItemSelector, (e) => {
				if (window.innerWidth > mobileMax) {
					toggleTab($(e.currentTarget).index(tabItemSelector));
				}
			});
        };

        const addEvents = () => {
            onClick();
            onResize();
            window.addEventListener('resize', onResize);
        };

		const init = () => {
			$(document).ready(() => addEvents());
		};

		return {
			init
		};

	})();

	productTabs.init();
	tabs.init();

});
