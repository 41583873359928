require([
    'jquery',
  ],
  ($) => {

    const prefetch = (() => {

        const getCategoryLinks = () => $('.page-products .block-layered-nav a');

        const init = () => {
            const $links = getCategoryLinks();
            if (!$links.length) {
                return;
            }
            $links.on('mousedown', (e) => {
                const link = document.createElement('link');
                link.setAttribute('rel', 'prefetch');
                link.setAttribute('href', e.currentTarget.href);
                document.head.appendChild(link);
            });
        };

        return {
            init
        };

    })();

    prefetch.init();
});
